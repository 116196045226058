const throttle_state = {};

const throttle = (state = throttle_state, action) => {
	switch (action.type) {
		case "SET_THROTTLE":
			return { ...state, [action.key]: action.data };
		default:
			return state;
	}
};

export default throttle;
