import axios from 'axios';

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
});

export const toggle_popup = (bool, tipe) => ({
  type: 'TOGGLE_POPUP',
  bool,
  tipe,
});

export const toggle_loader = (data) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const put_data = (key, data) => ({
  type: 'PUT_DATA',
  key,
  data,
});

export const get_translate = () => {
  return (dispatch) => {
    const main_localize = cdn.get(`data.json?${Math.random() * 10000}`);

    axios
      .all([main_localize])
      .then((resp) => {
        const json = { ...resp[0].data.data };
        dispatch(put_data('lang_json', json));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const catch_error = (err_msg) => {
  return (dispatch) => {
    if (err_msg) {
      dispatch(put_data('err_msg', err_msg));
    }
    dispatch(toggle_popup(true, 'error'));
    dispatch(toggle_loader(false));

    if (err_msg === 'invalid_region') {
      localStorage.clear();
    }
  };
};
