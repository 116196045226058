import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';

// Component
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
import LoadingScreen from 'helper/component/loadingscreen';
import Fallback from 'helper/component/fallback';
import { componentLoader } from 'helper';
import { get_translate } from 'redux/actions/main';

// Pages
const Home = lazy(() => componentLoader(() => import('pages/Home')));
const Register = lazy(() => componentLoader(() => import('pages/Register')));
const Faq = lazy(() => componentLoader(() => import('pages/Faq')));

export const Main = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.main);

  useEffect(() => {
    dispatch(get_translate());
  }, [dispatch]);

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div id="Main" className="main-panel">
        <ErrorBoundary fallback={Fallback}>
          <Suspense fallback={<LoadingScreen />}>
            <Loader loader={loader} />
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default Main;
